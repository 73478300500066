<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            top
            color="primary"
        ></v-progress-linear>
        <v-card-text class="text-center">
          <img src="/form_logo.png">
          <h6 >
            Field service management portal
          </h6>
          <v-form>
            <v-text-field
                label="email"
                v-model="email"
                :rules="emailRules"
                required
            />

            <v-text-field
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                name="input-10-2"
                label="password"
                :counter="10"
                :rules="nameRules"
                v-model="ePassword"
                @click:append="show = !show"
            ></v-text-field>
            <v-btn
                class="mb-4"
                @click="formSubmit"
                block
                color="primary"
                dark
            >
              <v-icon left>mdi-login</v-icon>
              Sign In
            </v-btn
            >
            <div class="d-flex justify-around flex-wrap">
              <router-link to="/app/sessions/forgot">
                <v-btn text small color="primary" class="mb-2"
                >Forgot Password
                </v-btn
                >
              </router-link>
            </div>
          </v-form>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>
<script>

import {mapGetters} from 'vuex'

export default {
  name: 'login',
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'Sign In'
  },
  data() {
    return {
      show: false,
      password: 'Password',
      checkbox1: true,
      checkbox2: false,
      email: '',
      ePassword: '',
      loading: false,
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      nameRules: [
        (v) => !!v || 'Password is required',
      ]
    }
  },
  computed: {
    ...mapGetters(['loggedInUser', 'error'])
  },
  methods: {
    //...mapActions(['login']),
    login() {
      var store = this.$store
      store.dispatch('auth/authenticate', {
        strategy: 'local',
        email: this.email,
        password: this.ePassword
      }).then((result) => {
        store.dispatch('login', result.user)
        this.$router.push('/app/dashboard');
      })
          .catch((error) => {
            alert(error)
          });

    },
    formSubmit() {
      this.login({email: this.email, password: this.ePassword})
    },
  },
  watch: {
    loggedInUser(val) {
      if (val && val.uid && val.uid.length > 0) {
        console.log('logged in successfully ')
        this.loading = true
        setTimeout(() => {
          this.$router.push('/app/dashboard')
        }, 500)
      }
    },
    error(val) {
      if (val != null) {
        this.makeToast("warning", val.message);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.page-wrap {
  background-color: #242939 !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}

.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
